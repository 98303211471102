import {
  GAME_LOADING,
  GAME_LOADED,
  GAME_ERROR,
  GAME_DELETE,
  GAME_UPDATED,
  GAME_ORDER_UPDATED,
  GAME_TRADE_UPDATED,
  HIDE_TIME_ADDED,
  UPDATE_WIDGET,
  NC_GAME_ERROR,
  PLAYER_LEFT,
  PLAYER_LEFT_ADMIN,
  PLAYER_RECONNECTED,
  ADMIN_TRADE_UPDATE,
  ADMIN_CHECK,
  NUMBER_ORDERS,
  NUMBER_TRADES,
  VIDEO_UPDATE,
  LEADER_UPDATE,
  FINAL_PNL,
  SET_TIME
} from "../utils/consts";

const initialState = {
  loading: null,
  gameId: null,
  gameElem: {},
  orders: [],
  myOrders: [],
  errors: null,
  widget: null,
  trades: [],
  adminTrades: {},
  positions: 0,
  profit: 0,
  timeIncreased: false,
  prevTime: 0,
  adminConnected: true,
  showTradeNotification: false,
  lastTradeChecked: null,
  showOrderNotification: false,
  lastOrdersChecked: [],
  firstOrderUpdate: true,
  currentPos: null,
  isPlaying: null,
  leaderBoard: null,
  finalPnl: null,
  leaderUpdateSent: null,
  timeLeft: null
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GAME_LOADING:
      return {
        ...state,
        gameElem: null,
        gameId: null,
        loading: true,
      };
    case GAME_LOADED:
      const timeIncreased = state.timeIncreased
        ? true
        : state.gameElem?.roundTime != 0 &&
          state.gameElem?.roundTime < payload.roundTime;
      if (state.gameElem) {
        payload.position.forEach((playerPayload) => {
          const playerElem = state.gameElem.position.filter(
            (playerIn) => playerIn._id === playerPayload._id
          )[0];
          if (playerElem)
            playerPayload.players.disconnected =
              playerElem.players.disconnected;
        });
      }
      return {
        ...state,
        gameElem: payload,
        orders: payload.orders,
        gameId: payload.id,
        loading: false,
        timeIncreased,
      };
    case GAME_UPDATED:
      if (state.gameElem)
        payload.position.every(
          (player, index) =>
            (player.disconnected =
              state.gameElem.position[index].players.disconnected)
        );
      return {
        ...state,
        gameElem: payload,
        loading: false,
      };
    case GAME_ORDER_UPDATED:
      return {
        ...state,
        orders: payload ? payload : [],
      };
    case GAME_TRADE_UPDATED:
      return {
        ...state,
        trades: payload ? payload.trades.reverse() : [],
        profit: payload?.profit,
        positions: payload?.positions,
      };
    case UPDATE_WIDGET:
      return {
        ...state,
        widget: payload,
      };
    case NC_GAME_ERROR:
      return {
        ...state,
        ...payload,
        loading: false,
      };
    case GAME_ERROR:
    case GAME_DELETE:
      return {
        ...state,
        ...payload,
        loading: false,
        gameId: null,
        gameElem: {},
      };
    case PLAYER_LEFT:
      state.gameElem.position.every((player) => {
        if (player.players._id === payload.id) {
          player.players.disconnected = true;
          return false;
        } else return true;
      });
      return {
        ...state,
      };
    case PLAYER_LEFT_ADMIN:
      let name;
      state.gameElem.position.every((player) => {
        if (player.players._id === payload.id) {
          player.players.disconnected = true;
          name = player.players.userName;
          return false;
        } else return true;
      });
      return {
        ...state,
        errors: { message: `${name} is disconnected!` },
      };
    case PLAYER_RECONNECTED:
      state.gameElem.position.every((player) => {
        if (player.players._id === payload.id) {
          player.players.disconnected = false;
          return false;
        } else return true;
      });
      return {
        ...state,
      };
    case HIDE_TIME_ADDED:
      return {
        ...state,
        timeIncreased: false,
      };
    case ADMIN_CHECK:
      const adminConnected =
        state.gameElem?.roundTime === 0 ||
        state.gameElem?.roundTime == null ||
        state.gameElem?.paused
          ? true
          : state.gameElem?.roundTime !== state.prevTime;
      state.prevTime = state.gameElem?.roundTime;
      if(state.leaderUpdateSent && state.gameElem?.roundTime > 0)
        state.leaderUpdateSent = false; 
      return {
        ...state,
        adminConnected,
      };
    case ADMIN_TRADE_UPDATE:
      state.adminTrades[payload.id] = payload;
      return {
        ...state,
      };
    case VIDEO_UPDATE:
      return {
        ...state,
        ...payload
      };
    case NUMBER_ORDERS:
      if (payload === -1) {
        state.showOrderNotification = false;
      } else {
        if (payload.differenceLength > 0) {
          state.showOrderNotification = true;
        } else if (payload.differenceLength < 0) {
          state.showOrderNotification = false;
        }
        console.log(payload.confirmationArray);
        state.lastOrdersChecked = payload.confirmationArray;
      }
      return {
        ...state,
      };
    case NUMBER_TRADES:
      if (payload === -1) {
        state.showTradeNotification = false;
      } else {
        state.showTradeNotification = true;
        state.lastTradeChecked = payload;
      }
      return {
        ...state,
      };
    case LEADER_UPDATE:
      if(payload === true)
        return {
          ...state,
          leaderUpdateSent: true
        }
      return {
        ...state,
        leaderBoard:payload
      };
    case FINAL_PNL:
      return {
        ...state,
        finalPnl:payload.finalProfit
      };
    case SET_TIME:
      return {
        ...state,
        timeLeft: payload
      }
    case "message":
      return Object.assign(state, { message: action.data });
    default:
      return state;
  }
}
