import React from "react";
import { Route } from "react-router-dom";
import { useSelector } from "react-redux";
import Login from "../../components/AuthPages/Login";
import AdminLogin from "../../components/AuthPages/AdminLogin";
import VerificationRequest from "../../components/AuthPages/VerificationRequest";
import Home from "../../components/Home";

const PrivateRoute = ({ component, isAdmin, ...options }) => {
  const isLoading = useSelector((state) => state.register.isLoading);
  const isAuthenticated = useSelector(
    (state) => state.register.isAuthenticated
  );
  const isAuthorizedRedirect = useSelector(
    (state) =>
      isAuthenticated && state.register.user && !state.register.user.confirmed
  );
  const isUserAdmin = useSelector(
    (state) =>
      isAuthenticated && state.register.user && state.register.user.adminName
  );
  let finalComponent =
    !isAuthenticated && !isLoading ? (isAdmin ? AdminLogin : Login) : component;
  finalComponent =
    isAuthorizedRedirect && isAdmin ? VerificationRequest : finalComponent;
  if (finalComponent === VerificationRequest && !isUserAdmin)
    finalComponent = Home;
  return <Route {...options} component={finalComponent} />;
};

export default PrivateRoute;
