import React from "react";
import SignIn from "../images/Sign-In.png";


const LoadingGif = (
  <div
    className="im"
    style={{ backgroundImage: `url("${SignIn}")` }}
    alt="background"
  >
    <div
      style={{
        left: "46%",
        position: "fixed",
        top: "40%",
        textAlign: "center",
      }}
    >
      <i className="fa fa-cog fa-8x fa-spin" style={{ color: "white" }} />
      <div style={{color:"white",position:"absolute"}}>Setting Things Up!</div>
    </div>
  </div>
);

const Loading = () => {
  return (
    <div
      className="im"
      style={{ backgroundImage: `url("${SignIn}")` }}
      alt="background"
    >
      <div   
        style={{
          left: "46%",
          position: "fixed",
          top: "40%",
          textAlign: "center",
        }}
      >
        <i className="fa fa-cog fa-8x fa-spin" style={{ color: "white" }} />
        <div style={{color:"white",position:"absolute"}}>Setting Things Up!</div>
      </div>
    </div>
  );
};

export { Loading, LoadingGif };
