import React, {useState} from "react";
import Bg from "./images/landingPage.jpg";
import { useHistory } from "react-router-dom";
import mobileBg from './images/mobileBg.png';
import logo from './images/Logo.png';
import { BrowserView, MobileView } from 'react-device-detect';

const Home = () => {
  const history = useHistory();
  const gotoPage = location => history.push(location);
  const [showButton, setShowButtons] = useState(false);
  const onLoad = () => setShowButtons(true);

  return (
    <>
    <BrowserView>
      <img
        className="im"
        src={Bg}
        alt="background"
        onLoad={onLoad}
      ></img>
      {showButton?<button className="btn home-button-login" onClick={(e) => gotoPage("/admin/login/")}>LOGIN</button>:<div/>}
      {showButton?<button className="btn home-button-register" onClick={(e) => gotoPage("/admin/register/")}>REGISTER</button>:<div/>}
    </BrowserView>
    <MobileView>
      <div
        className="im"
        style={{ backgroundImage: `url("${mobileBg}")` }}
        alt="background"
      ></div>
    </MobileView>
    <div>
      <div className="home-footer">
      Designed and Developed by 
      &nbsp;
      <img src={logo} style={{height:"2.5vh"}}></img>&nbsp;&nbsp;
      <a href="https://simplem.in/" style={{color:"#E5BC6D"}}>Simplem Solutions</a>
      </div>
    </div>
    </>
  );
};

export default Home;
