//Authentication request is done here, please refer to auth.js in reducer for handler logic

import axios from "axios";
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  USER_LOADED,
  LOAD_FAIL,
} from "../utils/consts";
import setAuthToken from "../utils/setAuthToken";
import { loadAdmin } from "./adminRegister";
import { setAlert } from "./alert";
// TODO: Set some alerts using the alert action generator

export const register = (registerData) => async (dispatch) => {
  const formData = new FormData();

  if (registerData.profileImage && registerData.profileImage.name) {
    formData.append(
      "profile",
      registerData.profileImage,
      registerData.profileImage.name
    );
    delete registerData.profileImage;
  }
  for (var key in registerData) {
    formData.append(key, registerData[key]);
  }
  try {
    const res = await axios.post("/api/user/create", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    dispatch({
      type: REGISTER_SUCCESS,
      payload: res.data,
    });
    dispatch(loadUser());
  } catch (err) {
    dispatch(
      setAlert(
        err.response.data.errors.msg,
        "danger"
      )
    );
    dispatch({
      type: REGISTER_FAIL,
    });
  }
};

export const login = ({ email, password, gameId }) => async (dispatch) => {
  try {
    const res = await axios.post(
      "/api/user/login",
      { email, password, gameId },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });
    dispatch(loadUser());
  } catch (err) {
    dispatch(setAlert(err.response.data.errors.msg, "danger"));
    dispatch({
      type: LOGIN_FAIL,
    });
  }
};

export const loadUser = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  try {
    const res = await axios.get("/api/user/");
    dispatch({
      type: USER_LOADED,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: LOAD_FAIL,
    });
    console.log(err.message);
  }
};

export const loadUserOrAdmin = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  } else {
    return;
  }
  try {
    const user = await axios.get("/api/user/");
    dispatch({
      type: USER_LOADED,
      payload: user.data,
    });
  } catch (err) {
    dispatch(loadAdmin());
    console.log(err.message);
  }
};

export const logout = () => (dispatch) => {
  dispatch({ type: LOGOUT });
};
