export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const USER_LOADED = "USER_LOADED";
export const LOAD_FAIL = "LOAD_FAIL";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGOUT = "LOGOUT";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const VERIFICATION_SUCCESS = "VERIFICATION_SUCCESS";
export const VERIFICATION_FAIL = "VERIFICATION_FAIL";
export const ADMIN_LOADED = "ADMIN_LOADED";
export const GAME_LOADED = "GAME_LOADED";
export const GAME_UPDATED = "GAME_UPDATED";
export const GAME_LOADING = "GAME_LOADING";
export const GAME_DELETE = "GAME_DELETE";
export const GAME_ERROR = "GAME_ERROR";
export const GAME_ORDER_UPDATED = "GAME_ORDER_UPDATED";
export const GAME_TRADE_UPDATED = "GAME_TRADE_UPDATED";
export const NC_GAME_ERROR = "NC_GAME_ERROR"; //no need to clean here, minor gameplay/flow error handling
export const UPDATE_WIDGET = "WIDGET_UPDATE";
export const HIDE_TIME_ADDED = "HIDE_TIME_ADDED";
export const PLAYER_LEFT = "PLAYER_LEFT";
export const PLAYER_LEFT_ADMIN = "PLAYER_LEFT_ADMIN";
export const PLAYER_RECONNECTED = "PLAYER_RECONNECTED";
export const ADMIN_TRADE_UPDATE = "ADMIN_TRADE_UPDATE";
export const ADMIN_CHECK = "ADMIN_CHECK";
export const NUMBER_ORDERS = "NUMBER_ORDERS";
export const NUMBER_TRADES = "NUMBER_TRADES";
export const VIDEO_UPDATE = 'VIDEO_UPDATE';
export const LEADER_UPDATE = 'LEADER_UPDATE';
export const FINAL_PNL = 'FINAL_PNL';
export const SET_TIME = 'SET_TIME';
/*
 * Enum for header on game page.
 * @readonly
 * @enum {Number}
 */
export const HEADER_TYPES = Object.freeze({
  START: 0,
  WAITING_FIRST: 1,
  ROUND: 2,
  PAUSED: 3,
  FINISH: 4,
});

/*
 * Enum for player sidebar pause overlay.
 * @readonly
 * @enum {Number}
 */

export const PLAYER_PAUSE_TYPES = Object.freeze({
  START_STATUS_PAUSE: 0,
  START_PAUSE: 1,
  ADMIN_PAUSE: 2,
  ROUND_PAUSE_DIGIT: 3,
  ROUND_PAUSE_WAIT: 4,
  GAME_ENDED: 5,
  INTERNET_PAUSE: 6,
});

/*
 * Enum for admin sidebar.
 * @readonly
 * @enum {Number}
 */
export const ADMIN_SIDEBAR_TYPES = Object.freeze({
  START_GAME: 0,
  ROUND_GAME: 1,
  ROUND_INIT: 2,
  ROUND_LAST_30: 3,
  FINISH_GAME: 4,
});

export const Time = {
  "0:30": 30,
  "1:30": 90,
  "2:00": 120,
  "3:00": 180,
  "4:00": 240,
  "5:00": 300,
};

export const Seats = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"];

export const color = (type) =>
  type === "buy" ? "rgb(13, 255, 38)" : "rgb(255, 77, 0)";

export const colorStatus = (type) =>
  type === 1 ? "rgb(13, 255, 38)" : "rgb(255, 77, 0)";

export const actionReadable = (action) =>
  action[0].toUpperCase() + action.slice(1);

export const invertAction = (action) => (action === "buy" ? "sell" : "buy");
