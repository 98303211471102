import React, { lazy, Suspense, Fragment, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { Loading, LoadingGif } from "./components/elements/Loading";
import { Provider } from "react-redux";
import store from "./store";
import setAuthToken from "./utils/setAuthToken";
import { loadUserOrAdmin } from "./actions/register";
import "./components/styles/main.css";
import PrivateRoute from "./utils/routing/PrivateRoute";
const Alerts = lazy(() => import("./components/elements/Alerts"));

const AdminLogin = lazy(() => import("./components/AuthPages/AdminLogin"));
const AdminRegister = lazy(() =>
  import("./components/AuthPages/AdminRegister")
);
const VerificationRequest = lazy(() =>
  import("./components/AuthPages/VerificationRequest")
);
const Confirm = lazy(() => import("./components/AuthPages/Confirm"));
const AdminHome = lazy(() => import("./components/AdminPages/AdminHome"));
const AdminOngoing = lazy(() => import("./components/AdminPages/AdminOngoing"));
const AdminGamePage = lazy(() => import("./components/AdminPages/AdminGame"));

const Register = lazy(() => import("./components/AuthPages/Register"));
const Login = lazy(() => import("./components/AuthPages/Login"));
const PlayerGame = lazy(() => import("./components/PlayerPages/PlayerGame"));

const Home = lazy(() => import("./components/Home"));

if (localStorage.token) {
  setAuthToken(localStorage.token);
}
const App = () => {
  useEffect(() => {
    store.dispatch(loadUserOrAdmin());
  }, []);
  return (
    <Provider store={store}>
      <Router>
        <Suspense fallback={LoadingGif}>
          <Fragment>
            <section>
              <Alerts />
              <Switch>
                <Route exact path="/verify" component={VerificationRequest} />
                <Route exact path="/confirm/:token" component={Confirm} />
                <Route exact path="/admin/register" component={AdminRegister} />
                <Route exact path="/admin/login" component={AdminLogin} />
                <Route exact path="/register/:gameId" component={Register} />
                <Route exact path="/login" component={Login} />

                <PrivateRoute
                  exact
                  path="/admin/home"
                  isAdmin={true}
                  component={AdminHome}
                />
                <PrivateRoute
                  exact
                  path="/admin/ongoing"
                  isAdmin={true}
                  component={AdminOngoing}
                />
                <PrivateRoute
                  exact
                  path="/admin/previous"
                  isAdmin={true}
                  component={AdminOngoing}
                />
                <PrivateRoute
                  exact
                  path="/admin/game/:gameId"
                  isAdmin={true}
                  component={AdminGamePage}
                />

                <PrivateRoute
                  exact
                  path="/game/:gameId"
                  component={PlayerGame}
                />

                <Route exact path="/" component={Home} />
                <Redirect from="*" to="/" />
              </Switch>
            </section>
          </Fragment>
        </Suspense>
      </Router>
    </Provider>
  );
};

export default App;
