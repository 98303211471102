import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import { adminLogin } from "../../actions/adminRegister";
import "../styles/reg.css";
import Axxella from "../images/Axxela.png";
import SignIn from "../images/Sign-In.png";

const AdminLogin = () => {
  const [formData, setFormData] = useState({
    userName: "",
    email: "",
    password: "",
  });
  const isAuthenticated = useSelector(
    (state) => state.register.isAuthenticated
  );

  const dispatch = useDispatch();

  const onChangeHandler = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(adminLogin(formData));
  };

  if (isAuthenticated) {
    return <Redirect to="/admin/home" />;
  }

  return (
    <div
      className="im"
      style={{ backgroundImage: `url("${SignIn}")` }}
      alt="background"
    >
      {" "}
      {/*Background Image*/}
      <div className="row no-gutters">
        <div className="col-md-3 no-gutters"></div>
        <div className="col-md-6 no-gutters">
          <Form className="style1" onSubmit={onSubmit}>
            <div className="axxella">
              <img src={Axxella} alt="" className="axxella-image" />
            </div>
            <br></br>
            <br></br>
            <Form.Group controlId="formEmail">
              <Form.Control
                className="login-input"
                type="email"
                name="email"
                placeholder="Your Email Address"
                size="lg"
                required
                onChange={onChangeHandler}
              />
            </Form.Group>
            <Form.Group controlId="formPassword">
              <Form.Control
                className="login-input"
                type="password"
                name="password"
                placeholder="Your Password"
                size="lg"
                required
                onChange={onChangeHandler}
              />
            </Form.Group>
            <button type="submit" className="btn submit">
              SIGN IN
            </button>
            <Link to={`/admin/register`}>
              <button className="btn submit-1">NEW TO THE GAME</button>
            </Link>
          </Form>
        </div>
        <div className="col-md-3 no-gutters"></div>
      </div>
    </div>
  );
};

export default AdminLogin;
