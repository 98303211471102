import axios from "axios";
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  VERIFICATION_SUCCESS,
  VERIFICATION_FAIL,
  ADMIN_LOADED,
  LOAD_FAIL,
  LOGOUT,
} from "../utils/consts";
import setAuthToken from "../utils/setAuthToken";
import { setAlert } from "./alert";

export const adminRegister = (adminRegData) => async (dispatch) => {
  try {
    const formData = new FormData();
    if (adminRegData.profileImage && adminRegData.profileImage.name) {
      formData.append(
        "avatar",
        adminRegData.profileImage,
        adminRegData.profileImage.name
      );
      delete adminRegData.profileImage;
    }
    for (var key in adminRegData) {
      formData.append(key, adminRegData[key]);
    }
    const res = await axios.post("/api/admin/register", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    dispatch({
      type: REGISTER_SUCCESS,
      payload: res.data,
    });
    dispatch(loadAdmin());
  } catch (err) {
    dispatch(
      setAlert(
        err.response.status === 400
          ? "Already Registered"
          : "Registeration Failed",
        "danger"
      )
    );
    dispatch({
      type: REGISTER_FAIL,
    });
  }
};

export const adminLogin = ({ email, password }) => async (dispatch) => {
  try {
    const res = await axios.post(
      "/api/admin/login",
      { email, password },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });
    dispatch(loadAdmin());
  } catch (err) {
    console.log(err.message);
    dispatch(setAlert("Login Failed", "danger"));
    dispatch({
      type: LOGIN_FAIL,
    });
  }
};

export const loadAdmin = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  } else {
    return;
  }
  try {
    const res = await axios.get("/api/admin/");
    dispatch({
      type: ADMIN_LOADED,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: LOAD_FAIL,
    });
    console.log(err.message);
  }
};

export const verify = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/admin/confirm/${id}`);
    dispatch({
      type: VERIFICATION_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err.message);
    dispatch({
      type: VERIFICATION_FAIL,
    });
  }
};

export const logout = () => (dispatch) => {
  dispatch({ type: LOGOUT });
};
