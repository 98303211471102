import { combineReducers } from "redux";
import alert from "./alert";
import register from "./register";
import game from "./game";

export default combineReducers({
  alert,
  register,
  game,
});
