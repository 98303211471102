import React from "react";
import Axxella from "../images/Axxela.png";
import SignIn from "../images/Sign-In.png";
import "../styles/reg.css";

const VerificationRequest = () => (
  <div
    className="im"
    style={{ backgroundImage: `url("${SignIn}")` }}
    alt="background"
  >
    <div className="row no-gutters">
      <div className="col-md-3 no-gutters"></div>
      <div className="col-md-6 no-gutters style2">
        <div className="axxella">
          <img src={Axxella} className="axxella-image" alt="Axxella" />
        </div>
        <p className="ver-text">
          Thanks for Signing Up.<br></br>
          Please check your email and verify the same.<br></br>
          You may sign in after verification.
        </p>
      </div>
      <div className="col-md-3 no-gutters"></div>
    </div>
  </div>
);
export default VerificationRequest;
